import React from 'react'
import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />

      <section class="relative bg-gray-50 shadow-inner overflow-hidden">
        <div class="relative pt-6 pb-16 sm:pb-24">
          <div class="mt-16 mx-auto max-w-4xl px-4 sm:mt-24">
            <div class="text-center">
              <p className="text-base md:text-2xl text-primary-600 font-bold tracking-wide">
                Oh no...
              </p>
              <h1 class="mt-2 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-6xl">
                <span class="inline md:block xl:inline">
                  This page doesn't exist
                </span>
              </h1>
              <p class="mt-5 max-w-xs mx-auto text-left md:text-center text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Go back to the home page or make sure the URL you entered is correct.
              </p>

              <div class="mt-3 max-w-2xl mx-auto sm:flex sm:justify-center md:mt-4">                
                <Link to="/" class="w-full flex items-center justify-center text-base font-medium text-gray-800 hover:text-gray-500 md:py-4 md:text-lg md:px-10">
                  Go to home page &rarr;
                </Link>                
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>    
  )
}

export default NotFoundPage